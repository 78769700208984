<template>
  <v-skeleton-loader
      type="actions"
      v-if="loadingCategories || !currentProduct"
    ></v-skeleton-loader>
  <div v-else>    
    <v-container fluid class="mb-0">
    <v-row style=" background-color: #ff6600;">
      <v-col><h1 class="text-center main-title">{{ title }}</h1></v-col>
    </v-row>
  </v-container>
 
    <v-container grid-list-lg class="mt-3">
      <v-layout row wrap>
        <v-flex xs12 sm6></v-flex>
        <v-flex xs12 sm6>
          <v-toolbar flat dense class="navigation">
            <v-btn small text @click="backButton" class="pl-0" >
              <v-icon left>mdi-chevron-left</v-icon>
              {{ $t("product.backToCategory") }}
            </v-btn>
          </v-toolbar>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs12 sm6>
          <v-skeleton-loader
            type="image"
            v-if="loadingCategories"
          ></v-skeleton-loader>        
          <div class="product-title-container">
            <v-img :max-width="580" :src="getProductImage(imageData)" >
              <div class="text-center" style="width: 37%;margin-top: 4%;">
                <h2 class="white--text">{{
                  $t("homepage.currency", {
                      price: formatThousands(currentProduct.amountWithVat)})
                  }}</h2>                
              </div>                                    
            </v-img>         
          </div>
        </v-flex>
        <v-flex xs12 sm6>
          <product-detail />
        </v-flex>
      </v-layout>
    </v-container>
  </div>

</template>

<script>
import ProductDetail from "../components/Product/ProductDetail";
import { getProductImage } from "@/Helper";
import { mapActions, mapGetters } from "vuex";
import { formatThousands } from "@/Helper";

export default {
  name: "ProductPage",
  metaInfo() {
    return {
      title: this.currentProduct ? this.currentProduct.name : "",
    };
  },  
  mixins: [formatThousands, getProductImage],
  components: {
    ProductDetail,
  },
  computed: {
    ...mapGetters(["currentCategory","allProducts", "currentProduct", "loadingCategories"]),
    imageData() {
      if (this.currentProduct) {
        return this.currentProduct.imageUrl;
      }

      return null;
    },
    title() {
      if (this.currentCategory) {
        return this.currentCategory.name;
      }

      return "Obchod";
    },
    titleColor(){            
      if (this.currentCategory && this.currentCategory.customData  && this.currentCategory.customData.backgroundColor) {
        return this.currentCategory.customData.backgroundColor;   
      }             
      
      return "#9b9c9c";
    },
  },
  watch: {    
    currentProduct(currentProd) {
      if (currentProd) {        
        this.changeCategory({ categoryId: currentProd.productCategoryId, langChange: false});
      }           
    }
  },
  mounted() {
    let productAlias = this.$router.currentRoute.params;
    if (typeof productAlias.alias !== "undefined") {
      this.changeProduct({ langChange: false });
    }
  },
  methods: {
    ...mapActions(["changeProduct","changeCategory"]),
    backButton() {
      if (this.currentCategory) {
        this.$router.push("/category/" + this.currentCategory.alias);
        return;
      }
      this.$router.push("/");
    }
  },
};
</script>
<style lang="scss">
  .navigation .v-toolbar__content {
    padding-left: 0;
  }

  .main-title {
    background-color: #ff6600;
    font-size: 28pt;
    line-height: 28pt;
    font-weight: lighter;
    color:#ffffff;   
  }
</style>

