<template>
  <v-container grid-list-lg>
    <v-layout row wrap v-if="loadingCategories">
      <v-flex xs12 sm6 lg3 v-for="n in 3" :key="n">
        <v-skeleton-loader type="card"></v-skeleton-loader>
      </v-flex>
    </v-layout>
    <v-layout row wrap class="category-products mb-10 justify-center">
      <v-flex xs12 sm6 md6 lg4 v-for="product in allProducts" :key="product.id">
        <v-card flat class="text-center transparent">
          <h3
            class="product-title mb-1"
            @click="$router.push('/product/' + product.alias)"
          >
            {{ product.name }}
          </h3>
          <div>            
            <v-img
              class="cursor-pointer"              
              :src="getProductImage(product.imageUrl)"
              @click="$router.push('/product/' + product.alias)"              
            >
            <div class="text-center" style="width: 37%;margin-top: 4%;">
              <h2 class="white--text" :style="fontHeight">
                {{
                  $t("homepage.currency", {
                      price: formatThousands(product.amountWithVat)})
                  }}                
              </h2>
            </div>                        
         <!--   <div class="text-lowercase text-center white--text" style="position: absolute; bottom: 0px; margin-left: 2px; margin-right: 2px; margin-bottom: 8px; width: 50%;">                        
            <div :style="{'font-size': fontHeightCart, 'line-height':fontHeightCart}" >
              <span style="vertical-align: middle;">{{ $t("cart.addToCart") }}</span>
              <img
              :style="{'height': fontHeightCart, 'line-height':fontHeightCart}"
                class="cart-image ml-1"                
                :src="require('../../assets/shoping_bag_white.svg')"
              />            
            </div>
          </div> -->
            </v-img>
            
          </div>
          <v-card-text class="pt-0 px-0 text-center cart-button-wrapper">
          </v-card-text>
          <v-card-text class="description pt-0">
            <strong v-html="product.shortDescription"></strong>
            <div v-if="product.inStock">{{ $t("product.inStock",{ inStock: formatThousands(product.inStock)})}}</div>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { getProductImage } from "@/Helper";
import { mapGetters, mapActions } from "vuex";
import { formatThousands } from "@/Helper";

export default {
  name: "Products",
  mixins: [formatThousands, getProductImage],
  computed: {
    ...mapGetters(["allProducts", "loadingCategories"]),   
    fontHeight () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 'font-size:20pt'
        case 'sm': return 'font-size:16pt'
        case 'md': return 'font-size:12pt'
        case 'lg': return 'font-size:16pt'
        case 'xl': return 'font-size:18pt'
      }    
      return '';
  },
  fontHeightCart () {    
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '9pt'
        case 'sm': return '9pt'
        case 'md': return '7pt'
        case 'lg': return '10pt'
        case 'xl': return '10pt'
      }    
      return '';
  }
  },
  watch: {
    allProducts: function (newValue) {
      if (newValue) {
        if (this.$gtm.enabled()) {
          let products = [];
          for (let i in this.allProducts) {
            products.push({
              id: this.allProducts[i].productId,
              name: this.allProducts[i].name,
              price: this.allProducts[i].amountWithVat,
              category: this.allProducts[i].productCategoryName,
              list: "Home Page",
              position: i + 1,
            });
          }
          this.$gtm.trackEvent({
            event: "productImpression", // Event type [default = 'interaction'] (Optional)
            category: "Ecommerce",
            ecommerce: {
              impressions: products,
            },
          });
        }
      }
    },
  },
  methods: {
    ...mapActions(["addToCart"]),
  },
};
</script>

<style lang="scss">
.category-products {
  .cart-button {
    text-transform: initial;
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .cart-image {    
    vertical-align: middle;    
  }
  .price {
    strong {
      display: inline-flex;
      margin-top: 5px;
      font-size: 13px;
    }
  }
  .product-title {
    text-transform: uppercase;    
  }
  .description {    
    p {
      margin-bottom: 0 !important;
    }
  } 
  .v-card__text{
      padding-bottom: 4px;
    }

}
</style>
