<template>
    <v-container fluid class="white--text important-links pa-0">
        <v-container>
            <v-row justify="space-between" dense >
                <v-col cols="6" sm="6" style="display: flex; align-items:center;" >
                    <strong class="mr-2"><a :href="$t('footer.privacyPolicyPdf')" class="no-decoration white--text" target="_blank">{{ $t('footer.privacyPolicy') }}</a></strong>
                </v-col>
                <v-col cols="6" sm="6" class="text-right">                    
                    <a href="https://www.pruhonickypark.cz" class="no-decoration white--text mr-2" target="_blank"><img style="vertical-align:middle" :src="require('../../assets/icon/web.svg')" width="24" /></a>
                    <span class="mr-3"><a href="https://pruhonickypark.cz" class="no-decoration white--text" target="_blank">pruhonickypark.cz</a></span>
                </v-col>
            </v-row>
        </v-container>
    </v-container>
</template>

<script>
    export default {
        name: 'ImportantLinks'
    }
</script>

<style lang="scss">
    .no-decoration {
        text-decoration: none;
    }
    .important-links {
        background-color: #c0c71c;
        font-size: 14px;
        h4 {
            font-weight: 600;
            text-transform: uppercase;
            font-size: 14px;
        }
    }
</style>
