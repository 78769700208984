<template>
  <div class="my-0 my-sm-4">
    <v-container class="main-header py-0 px-0" :class="{ isCheckout: isCheckout }">
      <v-toolbar flat>
        <v-btn @click="menu = true" class="mr-2 hamburger-menu" icon v-if="!isCheckout">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
        <router-link to="/" class="main-link">
          <!-- :src="require('../../assets/logo_sirka.png')" -->
          <img :src="require('../../assets/logo.svg')" class="custom-logo" :class="{ isCheckout: isCheckout }"
            itemprop="logo" v-if="$vuetify.breakpoint.smAndUp && !isCheckout" />
          <img v-else :src="require('../../assets/logo.svg')" class="mobile-custom-logo" />
        </router-link>
        <v-spacer class="divider"></v-spacer>
        <div class="main-menu" v-if="!isCheckout">
          <v-btn :class="{ 'ml-2': index > 0, }" :outlined="true" :text="false" plain
            :to="'/category/' + category.alias" v-for="(category, index) in allCategories" :key="index">{{ category.name
            }}</v-btn>
        </div>
        <v-spacer></v-spacer>

 <!--        <div class="text-center" :style="{ 'width': $vuetify.breakpoint.smAndUp ? '82px' : '58px' }">
          <v-btn icon style=" border: 1.5px solid #ffffff; border-radius: 15px; text-transform: uppercase" class="pa-1">
            <img class="cart-image" :src="require('../../assets/icon/login.svg')" />
          </v-btn>
          <div v-if="$vuetify.breakpoint.smAndUp" style="line-height: 80%;margin-top: 6px;color:#ffffff">
            <small>{{ $t("homepage.menuLogin") }}</small>
          </div>
        </div> -->

        <div class="text-center" :style="{ 'width': $vuetify.breakpoint.smAndUp ? '82px' : '58px' }">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="#ffffff" icon v-bind="attrs" v-on="on"
                style=" border: 1.5px solid #ffffff; border-radius: 15px; text-transform: uppercase" class="pa-1">
                {{ activeLocale }}
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item v-for="(item, index) in locales" :key="index" @click="changeLocale(item)">
                <v-list-item-title class="text-uppercase" style="color: #000000;">{{
      item
    }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <div v-if="$vuetify.breakpoint.smAndUp" style="line-height: 80%;margin-top: 6px;color:#ffffff">
            <small>{{ $t("homepage.menuLanguage") }}</small>
          </div>
        </div>

        <div class="shopping-cart text-center" :class="{ isCheckout: isCheckout }"
          :style="{ 'width': $vuetify.breakpoint.smAndUp ? '82px' : '58px' }">
          <v-badge :content="itemsInCart" :value="itemsInCart" text-color="white" overlap
            :offset-y="$vuetify.breakpoint.xsOnly ? 50 : 0" :offset-x="$vuetify.breakpoint.xsOnly ? 50 : 0">
            <v-btn icon style=" border: 1.5px solid #ffffff; border-radius: 15px; text-transform: uppercase;"
              class="pa-1" @click="drawer = true">
              <img class="cart-image" :src="require('../../assets/icon/shoppingcart.svg')" />
            </v-btn>
          </v-badge>
          <div v-if="$vuetify.breakpoint.smAndUp" style="line-height: 80%;margin-top: 6px;color:#ffffff">
            <small>{{ $t("homepage.shoppingCart") }}</small>
          </div>
        </div>
      </v-toolbar>
    </v-container>
    <v-navigation-drawer temporary v-model="drawer" :right="true" fixed width="340">
      <cart-overlay @drawerClose="drawer = false" />
    </v-navigation-drawer>
    <v-navigation-drawer temporary absolute v-model="menu" fixed width="340">
      <v-toolbar dense flat>
        <v-toolbar-title class="text-uppercase font-weight-bold">{{
      $t("homepage.menuTitle")
    }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="menu = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <!-- <v-spacer v-if="!isCheckout"></v-spacer> -->
      <v-list>
        <v-list-item :to="'/category/' + category.alias" v-for="(category, index) in allCategories" :key="index">
          <v-list-item-content>
            <v-list-item-title>{{ category.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CartOverlay from "../Cart/CartOverlay";
import { LOCALES } from "@/i18n";

export default {
  name: "MainHeader",
  props: ["isCheckout"],
  data() {
    return {
      drawer: false,
      menu: false,
      locales: LOCALES,
      activeLocale: null,
    };
  },
  mounted() {
    this.activeLocale = this.$i18n.locale;
  },
  components: {
    CartOverlay,
  },
  computed: {
    ...mapGetters(["cartProducts", "allCategories"]),
    itemsInCart() {
      let cart = this.cartProducts;
      if (!cart[0]) return null;
      return cart.reduce((accum, item) => accum + item.qty, 0);
    },
  },
  methods: {
    changeLocale(item) {
      this.activeLocale = item;
      this.$i18n.locale = item;
      this.$store.dispatch("setLang", item);
    },
  },
};
</script>

<style lang="scss">
.main-header {
  .divider {
    font-size: 26pt;
    text-align: center;
    color: #ffffff;

    @media (max-width: 1270px) {
      display: none;
    }
  }

  .v-toolbar__content {
    padding: 0;

    @media (max-width: 1270px) {
      padding: 4px 16px;
    }
  }

  .theme--light.v-toolbar.v-sheet {
    background-color: #6e9933;
  }

  .main-menu {
    @media (max-width: 1270px) {
      display: none;
    }

    .v-btn {
      color: #ffffff !important;
      border-radius: 15px;
      border: 1.5px solid #ffffff;
    }

    .v-btn--active {
      background-color: #6e9933;
      // color:#ffffff !important;
    }
  }

  .hamburger-menu {
    display: none;

    @media (max-width: 1270px) {
      display: flex;
    }
  }

  .main-link {
    line-height: 18pt;
    font-size: 18pt;
    text-decoration: none;
  }

  .main-cart {
    display: inline-flex;
    cursor: pointer;
  }

  .cart-title {
    display: inline-flex;
    margin-top: 5px;
    color: #ffffff;
  }

  .cart-image {
    cursor: pointer;
    //width: 38px;
  }

  .mobileCart {
    max-width: 80px;
  }

  &.isCheckout {
    padding-top: 0;
    padding-bottom: 0;
  }

  .menu-item {
    a {
      padding: 0 1em;
      font-size: 16px;
      color: #ffffff !important;
      text-decoration: none;
      text-transform: uppercase;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  .main-title {
    color: #ffffff;
    font-weight: 300;
    text-transform: uppercase;
    margin-left: 40px;

    strong {
      display: block;
      font-weight: 700;
      font-size: 32px;
      line-height: 32px;
    }
  }

  .mobile-custom-logo {
    height: 48px;
  }

  .custom-logo {
    height: 76px;

    &.isCheckout {
      height: 76px;
    }
  }

  .menu-icon-link {
    border-radius: 15px !important;
  }

  .v-list,
  .v-menu__content {
    border-radius: 0 !important;
  }

  .v-menu__content {
    max-width: 100% !important;
    width: 100%;
    left: 0 !important;
  }

  .v-chip {
    .v-chip__content {
      cursor: pointer !important;
    }
  }

  .shopping-cart {
    &.isCheckout {
      display: none;
    }

    color:#ffffff !important;
  }

  .v-badge__badge.primary {
    color: #6e9933;
    background-color: #ffffff !important;
  }
}
</style>
