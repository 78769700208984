<template>
  <v-container class="footer-address white--text">
    <v-row>
      <v-col cols="12" sm="6" class="text-sm-left text-center">
        <p class="mb-1">Botanický ústav Akademie věd ČR, v.v.i.</p>
        <p class="mb-1"><img :src="require('../../assets/icon/location.svg')" />Zámek 1, 252 43 Průhonice</p>
        <p class="mb-1"><img :src="require('../../assets/icon/email.svg')" />ibot@ibot.cas.cz</p>
        <p class="mb-0"><img :src="require('../../assets/icon/phone.svg')" />+420 271 015 211</p>
      </v-col>
      <v-col cols="12" sm="6" class="text-sm-right text-center">
        <p></p>
        <div class="d-flex justify-sm-end justify-center mb-1  mr-3">
          <div class="text-center">
            {{ $t('footer.openingHours') }}<br><a href="https://www.pruhonickypark.cz/pro-navstevniky/oteviraci-doba/"
              target="_blank">{{ $t('footer.here') }}<br /><img style="height: 32px" class="mr-0"
                :src="require('../../assets/icon/time.svg')" /></a> </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "FooterAddress",
};
</script>

<style lang="scss">
.footer-address {
  p {
    font-weight: normal;
  }

  a:link,
  a:visited {
    color: #FFFFFF;
    text-decoration: none;
    font-weight: bold;
  }

  a:hover,
  a:active {
    background-color: none;
  }

  img {
    vertical-align: middle;
    margin-right: 10px;
    height: 24px;
  }
}
</style>
