import Vue from "vue"
import VueI18n from "vue-i18n"

Vue.use(VueI18n)

function loadLocaleMessages() {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  )
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

let navigatorLang
if (navigator.language.includes("pl")) {
  navigatorLang = "pl"
}

if (navigator.language.includes("en")) {
  navigatorLang = "en"
}

if (navigator.language.includes("cs")) {
  navigatorLang = "cs"
}

if (navigator.language.includes("de")) {
  navigatorLang = "de"
}

const storeVuex = JSON.parse(localStorage.getItem("vuex"))
let storeLang
if (!storeVuex) {
  storeLang = navigatorLang
} else {
  storeLang = storeVuex.lang == "cz" ? "cs" : storeVuex.lang
}

export default new VueI18n({
  locale: storeLang || navigatorLang || process.env.VUE_APP_I18N_LOCALE || "en",
  fallbackLocale:
    storeLang ||
    navigatorLang ||
    process.env.VUE_APP_I18N_FALLBACK_LOCALE ||
    "en",
  messages: loadLocaleMessages()
})

export const LOCALES = ["cs", "en"]
