<template>
  <v-container>
    <v-row class="mb-7" v-if="orderId">
      <v-col cols="12" class="text-center" v-if="!orderId.resultText">
        <h2 class="mt-7 mb-3">{{ $t("checkoutSuccess.successfulOrder") }}</h2>
        <p>
          {{ $t("checkoutSuccess.orderNumber") }}: {{ orderId.orderNumber }}
        </p>
        <div v-if="orderId.bankAccount" class="mb-3">
          <div>
            {{ $t("checkoutSuccess.bankAccount") }}:
            <strong>{{ orderId.bankAccount }}</strong>
          </div>
          <div>
            {{ $t("checkoutSuccess.amount") }}:
            <strong>
              {{
                $t("homepage.currency", {
                  price: orderId.totalWithVat,
                })
              }}
            </strong>
          </div>
          <div>
            {{ $t("checkoutSuccess.variableSymbol") }}:
            <strong>{{ orderId.bankVariableSymbol }}</strong>
          </div>
        </div>
        <div v-if="orderId.spaidQR" class="mb-3">
          <img :src="qrCode" alt="QrCode" />
        </div>
        <div v-if="$vuetify.breakpoint.xsOnly && orderId.spaidFile" class="my-3 mb-5">
          <div class="my-3">
            {{ $t("checkoutSuccess.payByApplicationText") }}
          </div>
          <v-btn color="primary" depressed small tile :href="orderId.spaidFile">{{
            $t("checkoutSuccess.payByApplicationButton") }}</v-btn>
        </div>
        <p>{{ $t("checkoutSuccess.notification") }}</p>

        <v-row class="mb-4" v-if="!loading">          
          <v-col cols="12" class="text-center pb-1">
            <h2>{{ $t("checkoutSuccess.downloadTickets") }}</h2>
          </v-col>
          <v-col cols="12" sm="6" offset-sm="3">
            <div v-for="(group, category) in groupProductReadyQrCodes" :key="category">
            <div class="text-subtitle-1 mb-1 mt-4 text-left">{{ category }}</div>
            <v-expansion-panels>
              <v-expansion-panel v-for="(item, index) in group" :key="item.id">
                <v-expansion-panel-header>
                  {{ '' + (index + 1) + '. ' + item.name }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-btn v-if="item.registrationUrl" large :href="item.registrationUrl" target="_blank" tile
                    color="primary" depressed>{{ $t("checkoutSuccess.ticketRegistration") }}</v-btn>
                  <v-btn v-else-if="item.giftCardUrl" large :href="item.giftCardUrl" target="_blank" tile color="primary"
                    depressed>{{ $t("checkoutSuccess.giftCard") }}</v-btn>
                  <div v-else>
                    <img :src="item.qrCode" :alt="item.name" />
                    <p>
                      {{ $t("checkoutSuccess.ticketNumber") }}:
                      <strong>{{ item.ticketNumber }}</strong>
                    </p>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
          </v-col>
          <v-col cols="12" class="text-center">            
            <v-btn v-if="canDownloadPdf" large :href="downloadPdfUrl" target="_blank" tile color="primary" depressed>{{
              $t("checkoutSuccess.downloadPdf") }}</v-btn>
          </v-col>
        </v-row>

        <v-btn depressed tile @click="$router.push('/')">{{
          $t("checkoutSuccess.backToStore")
        }}</v-btn>
      </v-col>
      <v-col cols="12" class="text-center" v-else>
        <h2 class="mt-7 mb-3">{{ $t("checkoutSuccess.orderError") }}</h2>
        <p>
          {{ $t("checkoutSuccess.orderNumber") }}: {{ orderId.orderNumber }}
        </p>
        <p>
          {{ $t("checkoutSuccess.error") }}:
          <span v-text="orderId.resultText"></span>
        </p>
        <v-btn color="primary" depressed tile @click="$router.push('/')">{{
          $t("checkoutSuccess.backToStore")
        }}</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import { format, parseISO } from "date-fns";

export default {
  name: "CheckoutSuccess",
  data() {
    return {
      qrCode: null,
      productReadyQrCodes: [],
      loading: true,
    };
  },
  mounted() {
    if (!this.orderId) {
      this.$router.push("/");
    } else {
      this.productQrCodes();
      if (this.orderId.spaidQR) {
        this.fetchQRCode(this.orderId.spaidQR);
      }
      if (this.$gtm.enabled() && this.orderId.products) {
        this.$gtm.trackEvent({
          event: "purchase", // Event type [default = 'interaction'] (Optional)
          category: "Ecommerce",
          action: "Purchase",
          ecommerce: {
            currencyCode: "CZK",
            purchase: {
              actionField: {
                id: this.orderId.orderNumber,
                revenue: this.orderId.totalWithVat,
                shipping: 0,
                //coupon:
              },
              products: this.orderId.products,
            },
          },
        });
      }
    }
  },
  computed: {
    ...mapGetters(["orderId"]),
    canDownloadPdf: function () {
      return !!this.productReadyQrCodes.find(x => x.downloadPdf);
    },
    downloadPdfUrl() {
      return process.env.VUE_APP_ROOT_API + process.env.VUE_APP_API_ROOT + "/order/" + this.orderId.orderId + "/ticket?format=pdf";
    },
    groupProductReadyQrCodes() {
      return this.productReadyQrCodes.reduce((acc, cur) => {
        const category = cur.categoryName;
        if (!acc[category]) {
          acc[category] = [];
        }
        acc[category].push(cur);
        return acc;
      }, {});
    }
  },
  methods: {
    formatDate(date) {
      return format(parseISO(date), "dd. MM. yyyy");
    },
    async fetchQRCode(code) {
      let response = await fetch(code);

      if (response.ok) {
        let json = await response.text();
        this.qrCode = json.slice(1, -1);
      } else {
        console.log(response.status);
      }
    },
    productQrCodes() {
      if (!this.orderId.products) {
        this.productReadyQrCodes = [];
        return;
      }
                  
      let urls = [];
      this.orderId.products.map((product) => {
        if (product.qrCodeUrl) {
          urls.push(
            window.axios.get(product.qrCodeUrl, {
              name: product.name,
              categoryName: product.categoryName,
              ticketNumber: product.ticketNumber,
              validFrom: product.validFrom,
              validTo: product.validTo,
              registrationUrl: product.registrationUrl,
              giftCardUrl: product.giftCardUrl,
              downloadPdf: product.downloadPdf,
            })
          );
        }
      });

      Promise.all(urls).then((responses) => {
        responses.map((response) => {
          this.productReadyQrCodes.push({
            name: response.config.name,
            categoryName: response.config.categoryName,
            qrCode: response.data,
            ticketNumber: response.config.ticketNumber,
            validFrom: response.config.validFrom,
            validTo: response.config.validTo,
            registrationUrl: response.config.registrationUrl,
            giftCardUrl: response.config.giftCardUrl,
            downloadPdf: response.config.downloadPdf,
          });
        });
        this.loading = false;
      });
    },
  },
};
</script>
